.content-page {
	line-height: 155%;

	h2,
	h3,
	h4,
	h5 {
		font-weight: 700;
		font-size: var(--font-size);
		line-height: 110%;
		letter-spacing: -0.01em;
		color: $color-blue-1;
	}

	h2 {
		--font-size: 32px;
	}

	h3 {
		--font-size: 24px;
	}

	h4 {
		--font-size: 20px;
	}

	h5 {
		--font-size: 16px;
	}

	p {}

	ul,
	ol {
		padding: 0;
		margin: 0 0 45px;

		li {
			&:not(:last-of-type) {
				margin-bottom: 8px;
			}
		}

		ul,
		ol {
			padding-left: 30px;
			margin-top: 8px;
		}
	}

	ul {
		padding: 0;
		list-style: none;

		li {
			&:not(:last-of-type) {
				margin-bottom: 8px;
			}

			ul {
				li {
					&::before {
						width: 8px;
					}
				}
			}

			&::before {
				content: '';
				display: inline-block;
				width: 12px;
				height: 2px;
				margin-right: 18px;
				margin-bottom: 0.25em;
				background-color: $color-green-4;
			}
		}
	}

	ol {
		list-style-position: inside;

		li {
			&::marker {
				color: $color-green-4;
			}
		}
	}

	blockquote {
		padding-left: 30px;
		border-left: 4px solid $color-green-4;
		line-height: 141%;
	}
}
// BS
@import "~bootstrap/scss/bootstrap.scss";

// FONTAWESOME
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css");

@import "./meta/_meta.scss";
@import "./base/_base.scss";

@import "./meta/_sprites.scss";
@import "./blocks/_faq.scss";

@import "./main/_header.scss";
@import "./main/_footer.scss";
@import "./main/_404.scss";
@import "./main/_content.scss";
@import "./main/_hero.scss";

// @import "./main/_welcome.scss";
// @import "./main/_features.scss";
// @import "./main/_ourwork.scss";
// @import "./main/_services.scss";
// @import "./main/_tariff.scss";
// @import "./main/_faq.scss";
// @import "./main/_contact.scss";

// @import "./blocks/_grid-control.scss";
// @import "./blocks/_pagination.scss";
// @import "./blocks/_lang-dropdown.scss";

// @import "_debug.scss";

.main {
    section {
        // padding: 30px 0;
        padding: 30px;
        padding-top: 5vh;
        padding-bottom: 5vh;
    }
    .section-header {
        text-transform: uppercase;
        font-weight: 800;
        margin-bottom: 1.5rem;
    }
    &__welcome {
        section {
            padding: 0;
        }
        .welcome {
            > .container > .row {
                position: relative;
                top: -15px;
                background-color: $color-white;
                padding: 30px 30px 0 30px;
                // padding-top: 30px;
            }
            &-header {
                color: $color-blue-2;
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 2rem;
            }
            // &-text {
            //     p {}
            // }
        } 
        .col {
            &-contact_us {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
            // &-about_us {}
        }
        .list-contacts {
            padding: 0;
            margin-bottom: 1rem;
            li {
                list-style-type: none;
                margin-bottom: 0.5rem;
                i {
                    vertical-align: middle;
                    margin-right: 15px;
                }
            }
            &__mail {
                a {
                    text-decoration: none;
                }
            }
            &__phone {}
            &__wa {
                a {
                    color: darken($color-green-5, 5%);
                    text-decoration: none;
                    &:hover, &:active { color: darken($color-green-5, 10%); } 
                }
            }
        }
    }
    &__features {
        section {
            border-top: 1px solid $color-steel-8;
        }
        .feature {
            &-image {
                text-align: center;
                // span {}
            }
            &-header {
                text-align: center;
                .header-text {
                    text-transform: uppercase;
                    color: $color-blue-2;
                    font-weight: 700;
                }
            }
            // &-body {
            //     .body {}
            // }
        }
    }
    &__ourwork {
        background: rgb(39,73,142);
        background: linear-gradient(45deg, rgba(39,73,142,1) 0%, rgba(22,49,106,1) 50%, rgba(39,73,142,1) 100%);
        color: $color-white;
        .section-header {}
        .ourwork {
            &-image {
                text-align: center;
                // span {}
            }
            &-header {
                text-align: center;
                .header-text {
                    text-transform: uppercase;
                    color: $color-blue-7;
                    font-weight: 700;
                }
            }
            // &-body {
            //     .body {}
            // }
        }
    }
    &__services {
        .section-header {
            color: $color-blue-2;
        }
        .list-services-item {
            .col {
                &-title {
                    display: flex;
                    align-items: center;
                }
                // &-body {}
            }
            .text {
                &-header {
                    color: $color-blue-2;
                    font-weight: 600;
                    font-size: 17px;
                }
                &-body {
                    background-color: $color-steel-19;
                    padding: 1rem 1.75rem;
                }
            }
        }
    }
    &__tariff {
        background-color: $color-steel-19;
        .section-header {
            color: $color-blue-2;
        }
        table {
            width: 100%;
            margin-bottom: 1rem;;
            thead {
                border-top: 4px solid;
                border-bottom: 1px solid;
            }
            // tr {}
            th {
                padding: 1rem;
                @include media-breakpoint-down(sm) {
                    padding: 0.5rem;
                }
            }
            td {
                padding: 1rem;
                border-bottom: 1px solid;
                @include media-breakpoint-down(sm) {
                    padding: 0.5rem;
                }
                strong {
                    color: $color-blue-2;
                }
            }
            &.tariff-first {
                thead {
                    color: $color-blue-14;
                    border-color: $color-blue-14;
                }
                td {
                    border-color: $color-blue-14;
                }
            }
            &.tariff-second {
                thead {
                    color: $color-green-5;
                    border-color: $color-green-5
                }
                td {
                    border-color: $color-green-5;
                }
            }
        }
        .tariff__sub-list {
            padding: 0;
            margin: 2rem 0 1rem;
            li {
                list-style-type: none;
                color: $color-steel-14;
                line-height: 1.75;
                span {
                    color: $color-blue-14;
                    font-weight: 700;
                }
            }
        }
    }
    &__faq {
        .section-header {
            color: $color-emerald-5;
        }
    }
    &__contact {
        background: rgb(1,170,129);
        background: linear-gradient(-180deg, rgba(1,170,129,1) 0%, rgba(31,168,174,1) 100%);
        color: $color-white;
        // .section-header {}
        .contact-card {
            background: rgb(0,54,97);
            background: linear-gradient(56deg, rgba(0,54,97,0.4) 0%, rgba(0,54,97,0.12) 100%);
            border-radius: 5px;
            min-height: 194px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            -webkit-box-shadow: 0px 4px 29px 0px rgba(247, 251, 255, 0.2);
            -moz-box-shadow: 0px 4px 29px 0px rgba(247, 251, 255, 0.2);
            box-shadow: 0px 4px 29px 0px rgba(247, 251, 255, 0.2);
            margin-bottom: 1rem;
            @include media-breakpoint-down(lg) {
                min-height: 130px;
            }
            &__inner {
                position: relative;
                width: 75%;
                padding-right: 50px;
                @include media-breakpoint-down(lg) {
                    width: 80%;
                }
            }
            i.sprite {
                display: block;
                position: absolute;
                top: -15px;
                right: -15px;
                &__block-mail {}
                &__block-phone {}
                &__block-wa {}
            }
            // &__icon {}
            p {
                font-size: 19px;
                margin-bottom: 0.5rem;
                @include media-breakpoint-down(sm) {
                    font-size: 17px;
                }
                a {
                    color: $color-green-8;
                    text-decoration: none;
                    font-weight: 600;
                    &:hover, &:active { color: $color-green-6 }
                }
            }
            &.card {
                // &__mail {}
                // &__phone {}
                &__wa {
                    a { color: $color-white; }
                }
            }
        }
    }
}

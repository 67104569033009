$xs: 320px;
$sm: 420px;
$md: 768px;
$lg: 960px;
$xl: 1200px;

// fonts
$ff-inter: 'Inter', sans-serif;
$ff-awesome: 'Font Awesome 6 Free';
// $ff-icon: 'icomoon';

$img-path: '/img/' !default;
$font-path: "/fonts/" !default;
$color-black: #222222;
$color-white: #FFFFFF;

$color-gray-0: #2F2F2F;
$color-gray-1: #434343;
$color-gray-2: #555555;
$color-gray-3: #686868;
$color-gray-4: #767676;
$color-gray-5: #8A8A8A;
$color-gray-6: #9F9F9F;
$color-gray-7: #BBBBBB;
$color-gray-8: #D6D6D6;
$color-gray-9: #EEEEEE;

$color-steel-0: #263238;
$color-steel-1: #37474F;
$color-steel-2: #455A64;
$color-steel-3: #546E7A;
$color-steel-4: #607D8B;
$color-steel-5: #78909C;
$color-steel-6: #90A4AE;
$color-steel-7: #B0BEC5;
$color-steel-8: #CFD8DC;
$color-steel-9: #ECEFF1;

$color-steel-19: #F1F8FA;
$color-steel-18: #E5F2F5;
$color-steel-17: #B6DAE2;
$color-steel-16: #91BFCD;
$color-steel-15: #77B0C1;
$color-steel-14: #679EAE;
$color-steel-13: #558D9D;
$color-steel-12: #4B7B8A;
$color-steel-11: #3E6976;
$color-steel-10: #305863;

$color-green-9: #CBFFF2;
$color-green-8: #71FEDC;
$color-green-7: #19FDC6;
$color-green-6: #09DFAB;
$color-green-5: #08C699;
$color-green-4: #00B388;
$color-green-3: #009672;
$color-green-2: #006C52;
$color-green-1: #004031;
$color-green-0: #002A20;
$color-green-19: #E0F2ED;
$color-green-18: #B3E0D5;
$color-green-17: #81CCBA;
$color-green-16: #4EB79E;
$color-green-15: #29A98A;
$color-green-14: #039975;
$color-green-13: #028B6A;
$color-green-12: #027B5E;
$color-green-11: #016A51;
$color-green-10: #014E3C;

$color-blue-9: #E7EFFF;
$color-blue-8: #BCD2FF;
$color-blue-7: #91B5FF;
$color-blue-6: #699AFF;
$color-blue-5: #4682FF;
$color-blue-4: #427DF5;
$color-blue-3: #3869CE;
$color-blue-2: #284B94;
$color-blue-1: #182D58;
$color-blue-0: #101D3A;
$color-blue-19: #E7ECF9;
$color-blue-18: #C4D2F1;
$color-blue-17: #9DB5E8;
$color-blue-16: #7597DE;
$color-blue-15: #5982D9;
$color-blue-14: #3B6CD1;
$color-blue-13: #3562BE;
$color-blue-12: #2F56A8;
$color-blue-11: #284A91;
$color-blue-10: #1E376B;
$color-blue-29: #E6EEFF;
$color-blue-28: #C4D1EA;
$color-blue-27: #A7B4CD;
$color-blue-26: #8B98B1;
$color-blue-25: #738099;
$color-blue-24: #6D7A93;
$color-blue-23: #5A6680;
$color-blue-22: #3E4A64;
$color-blue-21: #202D46;
$color-blue-20: #111E37;

$color-emerald-5: #0F7F84;
$color-gold-5: #EADD79;
.sprite {
    background: transparent url('#{$img-path}sprites.png') no-repeat 0 0;
    display: inline-block;

    &.sprite-feature {
        width: 124px;
        height: 124px;
        // display: block;
    }
    &__expa {
        background-position: 0 0;
    }
    &__clients {
        background-position: -124px 0;
    }
    &__transparency {
        background-position: -248px 0;
    }
    &__safety {
        background-position: -372px 0;
    }
    &__flexible {
        background-position: -496px 0;
    }

    &.sprite-ourwork {
        width: 124px;
        height: 124px;
        // display: block;
    }
    &__needs {
        background-position: 0 -124px;
    }
    &__consult {
        background-position: -124px -124px;
    }
    &__agreement {
        background-position: -248px -124px;
    }
    &__works {
        background-position: -372px -124px;
    }
    &__support {
        background-position: -496px -124px;
    }

    // &.sprite-communicate {
    //     display: block;
    // }
    &__mail {
        background-position: -449px -248px;
        width: 24px;
        height: 18px;
    }
    &__phone {
        background-position: -475px -248px;
        width: 18px;
        height: 26px;
    }
    &__wa {
        background-position: -495px -248px;
        width: 24px;
        height: 24px;
    }

    &.sprite-slogan {
        width: 75px;
        height: 80px;
        background-position: -372px -248px;
    }

    &.sprite-contact {
        width: 124px;
        height: 124px;
    }
    &__block-mail {
        background-position: 0 -248px;
    }
    &__block-phone {
        background-position: -124px -248px;
    }
    &__block-wa {
        background-position: -248px -248px;
    }

}
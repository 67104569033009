.err-page {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.err-block {
	max-width: 540px;
	padding: 20px;

	&__img {
		max-width: 100%;
	}

	&__title {
		max-width: 300px;
		margin-bottom: 22px;
		font-weight: 600;
		font-size: 48px;
		color: $color-green-5;
		font-weight: 800;
	}

	&__descr {
		font-weight: 400;
		line-height: 175%;
		color: $color-steel-3;
	}
	@include media-breakpoint-down(md) {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
	}
}
:root {
	--color-bg: #{$color-white};
	--color-fg: #{$color-steel-0};

	--header-h: 125px;
	--header-compact: 72px;

	@include media-breakpoint-down(lg) {
		--header-h: 72px;
		// --header-compact: 64px;
	}
}

body {
	width: 100%;
	min-width: 280px;
	min-height: 100vh;
	background: var(--color-bg);
	color: var(--color-fg);
	display: grid;
	grid-template-rows: auto 1fr auto;
	font-family: $ff-inter;
	font-weight: 400;
	font-size: 16px;
	line-height: 1.2;
}

.main {
	// padding: 30px 0;
	// padding: 0 0 30px;

	&__cat {}

	&__title {
		padding-top: 32px;
	}

	&__content {
		padding-top: 30px;
	}

	&__hero {
	}

}

.page-title {
	margin-bottom: 25px;
	color: #000;
	font-weight: 600;
	font-size: 48px;
	color: $color-blue-1;

	@include media-breakpoint-down(lg) {
		font-size: 36px;
	}

	@include media-breakpoint-down(md) {
		font-size: 32px;
	}

	@include media-breakpoint-down(sm) {
		font-size: 24px;
	}

	// span {
	// 	color: $color-blue-2;
	// }

}

// @import './icons.scss';
.accordion {
	display: flex;
	flex-direction: column;
	// gap: 10px;
}

.accordion-item {
	min-height: 50px;
	border: 2px solid $color-green-4;
	border-bottom-width: 0;
	background-color: transparent;
	transition: all 300ms;
	border-radius: 0 !important;

	// &:not(:first-of-type) {
	// 	background-color: ;
	// }

	/* active */
	&:not(:has(.collapsed)) {
		.accordion-item__title {
			background-color: lighten($color-green-9, 5%);
			color: $color-emerald-5;
		}
	}
	/* hover */
	&:has(.collapsed) {
		&:hover {
			background-color: lighten($color-blue-9, 2%);
			.accordion-item__title {
				color: $color-emerald-5;
			}
		}
	}

	&__title {
		border-bottom: 2px solid $color-green-4;
		margin: 0;
		padding: 5px 10px 5px 20px;
		display: flex;
		align-items: center;
		color: $color-steel-0;
		font-size: 18px;
		font-weight: 600;
		line-height: 21.25px;
		justify-content: space-between;
		@include media-breakpoint-down(md) {
			font-size: 17px;
		}

		&::after {
			content: '\f0d7';
			flex-shrink: 0;
			align-self: flex-start;
			width: 48px;
			height: 48px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: transparent;
			font-family: $ff-awesome;
			font-size: 22px;
			font-weight: 900;
			color: $color-green-4;
			transition: all 300ms;
		}

		&:not(.collapsed) {
			&::after {
				content: '\f0d8';
			}
		}

		&.collapsed {
			&:hover {
				&::after {
					background-color: var(--color-blue-5);
					color: $color-emerald-5;
				}
			}
		}
	}

	&__body {
		padding: 20px;
		color: $color-steel-0;
		border-bottom: 2px solid $color-green-4;
		min-height: 50px;
	}
}
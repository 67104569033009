.hero {
	color: $color-white;

	background: $color-green-5 url('#{$img-path}hero_light.jpg') no-repeat top center;
	background-size: cover;

	padding: 4vh 0 !important;

	&__heading {
		.slogan-block {
			display: flex;
			flex-direction: column;
			align-items: end;
			padding-right: 2rem;
			@include media-breakpoint-down(md) {
				align-items: start;
			}
			&__image {
				display: block;
				width: 75px;
				height: 80px;
				margin-bottom: 10px;
				@include media-breakpoint-down(md) {
					display: none;
				}
			}
			p {
				border: 6px solid $color-green-5;
				max-width: 215px;
				padding: 1rem 1.4rem;
				font-size: 18px;
				font-weight: 500;
				@include media-breakpoint-down(md) {
					max-width: none;
					font-size: 17px;
				}
			}
		}
	}
	&__title {
		text-transform: uppercase;
		font-weight: 800;
		width: 66%;
		font-size: 45px;
		padding-top: 0.5rem;
		@include media-breakpoint-down(md) {
			font-size: 35px;
		}
	}
	&__info {
		display: flex;
		align-items: center;
		height: 100%;
		p {
			border-bottom: 4px solid $color-white;
			padding-bottom: 2rem;
			font-size: 18px;
		}
		@include media-breakpoint-up(md) {
			padding: 0 13%;
		}
		@include media-breakpoint-down(md) {
			padding: 0;
		}
		
	}

}
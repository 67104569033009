.header {
	// position: sticky;
	top: 0;
	z-index: 2;
	box-shadow: 0 8px 16px 0 rgba(56, 73, 76, 0.1);

	&.home-page {
		background: $color-white url('#{$img-path}top_light.jpg') no-repeat top center;
		background-size: cover;
	}
	&.single-page {
		background: transparent;
		background-image: none;
	}

	// &-compact {
	// 	.header__inner { height: var(--header-compact); }
	// }

	&__inner {
		// padding: 5px 0;
		// height: var(--header-h);
		// @include media-breakpoint-down(sm) {
		// 	position: relative;
		// }
		padding: 15px 0 30px;
		@include media-breakpoint-down(md) {
			padding-bottom: 15px;
		}
	}

	// &__slogan {
	// 	color: $color-steel-4;
	// 	font-size: 14px;
	// 	line-height: 110%;
	// }

	// &__search {
	// 	@include media-breakpoint-down(sm) {
	// 		height: 42px;
	// 	}
	// }

	&__log {
		display: flex;
		// justify-content: center;
		justify-content: start;
	}

	.logo {
		display: block;
		max-width: 300px;
		@include media-breakpoint-down(md) {
			width: 240px;
		}
		&__img {
			display: block;
			max-width: 100%;
		}
	}

	&__menu {
		text-align: center;
		ul {
			display: flex;
			justify-content: center;
			margin: 0;
			padding: 15px 0 0 0;
			@include media-breakpoint-down(md) {
				justify-content: left;
			}
			@include media-breakpoint-down(md) {
				display: block;
				// text-align: left;
			}
			li {
				list-style-type: none;
				display: inline-block;
				margin: 5px 5px 5px 0;
				// @include media-breakpoint-down(md) {
				// 	display: block;
				// }
				@include media-breakpoint-down(sm) {
					display: block;
				}
				a {
					display: flex;
					align-items: center;
					width: 100%;
					height: 36px;
					padding: 0 15px;
					background-color: transparent;
					min-width: 75px;
					white-space: nowrap;
					color: $color-blue-2;
					text-decoration: none;
					border-radius: 3px;
					@include media-breakpoint-down(sm) {
						display: inline-block;
						height: auto;
						width: auto;
						padding: 6px 15px;
					}
					&:hover {
						background-color: $color-steel-19;
						// color: $color-blue-0;
					}
					&.is_active {
						background-color: $color-green-5;
						color: $color-white;
					}
				}
			}
		}
	}

}
.footer {
	border-top: 1px solid $color-steel-7;

	&__inner {
		padding: 30px 0;

	}

	&__logo {}

	&__copy {
		color: $color-steel-4;
		// padding-left: 58px;
		margin-bottom: 35px;
		span { color: $color-blue-14; }
		strong { color: $color-steel-0 }
	}

	&__menu {
		@include media-breakpoint-down(md) {
			padding-left: 58px;
		}
	}

	&__col {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	&__link {
		text-decoration: none;
		margin-bottom: 1em;
		color: $color-blue-14;
		font-size: 14px;

		&:hover {
			text-decoration: underline;
		}
	}
}